/* Overall Hero Container Styling */
.hero-container {
  background: url('./images/Hero_website_desktop.png') no-repeat center center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row; /* Align children in a row */
  align-items: center; /* Vertically center */
  justify-content: flex-start; /* Start from the left */
  color: white;
  text-align: left;
  position: relative; /* Needed for absolute positioning of children */
}

.highlight {
  color: #32CD32; /* Lime Green color */
}


.logo-container {
  position: absolute;
  top: 30px;
  left: 50px; /* Adjust to match the padding of hero-content */
  z-index: 10;
}

.logo-container img {
  height: 80px; /* Adjust size as needed */
  filter: brightness(0) invert(1); /* Makes the logo white */
}


/* Hero Section Content Styling */

.hero-content {
  width: 50%; /* Take up half of the hero container */
  padding-left: 50px; /* Match this with the left position of logo-container */
  box-sizing: border-box; /* Ensures padding is included in width */
}

.hero-content h2 {
  font-size: 15px; /* Adjusted from 10px to be more readable */
  margin-bottom: 2px;
  color: #E6E6FA; /* Lavender color */
}

.hero-content p {
  font-size: 15px; /* Adjusted from 10px to be more readable */
  margin-bottom: 2px;
  color: #E6E6FA; /* Lavender color */
}

.hero-content h1 {
  color: #E6E6FA; /* Lavender color */
  font-size: 38px; /* Larger font size for headline */
  margin-bottom: 20px;
  margin-top: 0;
  text-transform: uppercase; /* Capitalize text */
}

/* Countdown Styling */
.countdown {
  display: flex;
  justify-content: left;
  gap: 30px; /* Adjust the gap between elements as needed */
}

.countdown-element {
  display: flex; /* Add this to enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center-align the flex children horizontally */
  text-align: center;
}

.countdown-number {
  font-size: 120px; /* Size of the countdown numbers */
  color: #FFA500; /* Orange color */
  font-weight: bold;
  margin-bottom: 0; 
  padding-bottom: 1px;/* Reduced bottom margin */
}

.countdown-label {
  font-size: 15px; /* Size of the countdown labels */
  color: #E6E6FA; /* Lavender color */
  text-transform: uppercase;
  margin-top: 0;
 padding-top: 0;
 margin-bottom: 20px;
}


/* Button Styling */
.btn-download {
  background-color: #483D8B; /* Dark slate blue color */
  color: white;
  border: 1px solid #FFA500; /* Orange border */
  padding: 10px 30px;
  border-radius: 30px; /* Rounded edges for buttons */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  text-transform: uppercase; 
  text-decoration: none;/* Capitalize text */
}

.btn-download:hover {
  background-color: #FFA500; /* Orange background on hover */
  color: #483D8B; /* Text color changes to dark slate blue */
  text-decoration: none;
}



.download-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Adjust the gap between buttons */
  text-decoration: none; /* Remove underline */
}



.social-media {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.social-icon {
  color: white; /* Icon color */
  width: 20px; /* Icon width */
  height: 20px; /* Icon height */
  margin: 0 10px; /* Spacing between icons */
}

.social-icon:hover {
  color: #FFA500; /* Color on hover */
}

.hero-btns {
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-primary {
  background-color: blue;
  color: white;
}

.btn-secondary {
  background-color: gray;
  color: white;
}


/* Media query for mobile devices */
@media (max-width: 768px) {
    .hero-container {
    background: url('./images/Hero_website_mobile.png') no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align children to the bottom */
    align-items: center; /* Center children horizontally */
    height: 100vh; /* Full height of the viewport */
  }

  .hero-content {
    width: 100%; /* Full width of the container */
    height: 50vh; /* Half of the viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically in the hero-content area */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Padding around content */
    text-align: center; /* Center text */
  }


  .countdown-number {
  font-size: 50px; /* Size of the countdown numbers */
  color: #FFA500; /* Orange color */
  font-weight: bold;
  margin-bottom: 0; 
  padding-bottom: 1px;/* Reduced bottom margin */
    }

    .countdown-label {
  font-size: 8px; /* Size of the countdown labels */
  color: #E6E6FA; /* Lavender color */
  text-transform: uppercase;
  margin-top: 0;
 padding-top: 0;
 margin-bottom: 10px;
    }

    .hero-content h2 {
  font-size: 10px; /* Adjusted from 10px to be more readable */
  margin-bottom: 1px;
  color: #E6E6FA; /* Lavender color */
    }

    .hero-content p {
  font-size: 10px; /* Adjusted from 10px to be more readable */
  margin-bottom: 1px;
  margin-top: 10px;
  color: #E6E6FA; /* Lavender color */
}

    .hero-content h1 {
  color: #E6E6FA; /* Lavender color */
  font-size: 24px; /* Larger font size for headline */
  margin-bottom: 8px;
  margin-top: 0;
  text-transform: uppercase; /* Capitalize text */
    }

    .logo-container {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 5;
}

.logo-container img {
  height: 50px; /* Adjust size as needed */
  filter: brightness(0) invert(1); /* Makes the logo white */
}
}






/* Add more styles as needed */
